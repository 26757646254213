<template>
  <div class="container-page">
    <p class="title-inner">Карточка создания мероприятия</p>

    <div class="card-info">
      <div class="card-info__row">
        <div class="form-group w100">
          <div class="card-info__switch">
            <div :class="['card-info__switch-item', { active: !businessType }]" @click="switchType(false)">МПК/РГ</div>
            <div :class="['card-info__switch-item', { active: businessType }]" @click="switchType(true)">ДС</div>
          </div>
        </div>
        <div v-if="businessType" class="form-group w100 required">
          <ComponentInput
            :select2Settings="select2SettingsSearchAll"
            :select2Options="businessEventTypeList"
            v-model="item.mpkBusinessTipId"
            v-on:select2OnSelect="changeBussines"
            type="select2"
            placeholder="ДС"
            required
          />
        </div>
        <div v-else class="form-group w100">
          <ComponentInput
            :select2Settings="select2SettingsSearchAll"
            :select2Options="mpkFilterList"
            v-model="item.mpkId"
            v-on:select2OnSelect="changeMpk"
            type="select2"
            placeholder="МПК/РГ"
          />
        </div>
      </div>

      <div class="card-info__row">
        <div class="card-info__row-col">
          <div class="form-group required">
            <ComponentInput
              v-model="item.name"
              label="Название мероприятия"
              required
              maxLength="500"
              placeholder="Введите название"
            />
          </div>
        </div>
        <div class="card-info__row-col">
          <div class="form-group required">
            <ComponentInput
              v-if="businessType"
              label="Тип мероприятия"
              :select2Settings="select2Settings"
              :select2Options="getEventTypeListBA"
              v-model="item.eventTypeId"
              type="select2"
              required
            />
            <ComponentInput
              v-else
              label="Тип мероприятия"
              :select2Settings="select2Settings"
              :select2Options="getEventTypeListMPK"
              v-model="item.eventTypeId"
              type="select2"
              required
            />
          </div>
        </div>
      </div>

      <div class="card-info__row">
        <div class="card-info__row-col">
          <div class="form-group required">
            <ComponentInput
              label="Страна"
              :select2Settings="select2Settings"
              :select2Options="countryList"
              v-model="item.countryId"
              :disabled="item.mpkId > 0 || defaultCountryId > 0"
              type="select2"
              required
            />
          </div>
        </div>
        <div class="card-info__row-col">
          <div class="form-group date">
            <p class="form-label">Дата и время проведения</p>
            <div class="date-container" v-for="(el, n) in item.eventDatesList" :key="el.date">
              <DateTimeComponent v-model="el.date" />
              <IconComponent v-if="n == 0" @click="addEventDate()" name="plus_btn" />
            </div>
          </div>
        </div>
      </div>

      <div class="card-info__row">
        <div class="card-info__row-col">
          <div class="form-group">
            <ComponentInput v-model="item.place" label="Место" maxLength="500" placeholder="Введите место" />
          </div>
          <div class="form-group-checkboxes">
            <div class="checkboxInput" v-for="t in eventConductTypeList" :key="t.id">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="item.eventConductTypesIds"
                :value="t.id"
                :id="'eventConductType' + t.id"
              />
              <label :for="'eventConductType' + t.id" class="form-check-label form-label">{{ t.text }}</label>
            </div>
          </div>
        </div>

        <div class="card-info__row-col">
          <div class="form-group">
            <p class="form-label">Состав документов</p>
            <div class="form-group-checkboxes">
              <div class="checkboxInput">
                <input type="checkbox" class="form-check-input" v-model="item.isDocProtokol" id="isDocProtokol" />
                <label for="isDocProtokol" class="form-check-label form-label">Протокол</label>
              </div>

              <div class="checkboxInput">
                <input type="checkbox" class="form-check-input" v-model="item.isDocPovestka" id="isDocPovestka" />
                <label for="isDocPovestka" class="form-check-label form-label">Повестка пленарного заседания</label>
              </div>

              <div class="checkboxInput">
                <input type="checkbox" class="form-check-input" v-model="item.isDocProject" id="isDocProject" />
                <label for="isDocProject" class="form-check-label form-label">Проект распоряжения</label>
              </div>

              <!-- <div class="checkboxInput">
                  <input type="checkbox" class="form-check-input" v-model="item.isDocParticipant" id="isDocParticipant">
                  <label for="isDocParticipant" class="form-check-label form-label">Состав участников</label>
                </div> -->
            </div>
          </div>
        </div>
      </div>

      <div class="card-info__row">
        <div class="card-info__row-col">
          <div class="form-group">
            <p class="form-label">Разрешение регистрации</p>
            <div class="form-group-checkboxes">
              <div class="checkboxInput">
                <input
                  type="checkbox"
                  class="form-check-input"
                  v-model="item.isRegistrationPermit"
                  id="chbx_isRegistrationPermit"
                />
                <label for="chbx_isRegistrationPermit" class="form-check-label form-label">Регистрация разрешена</label>
              </div>
            </div>
          </div>
        </div>

        <div class="card-info__row-col">
          <div class="form-group">
            <p class="form-label">Закрытие регистрации</p>
            <div class="form-group-container">
              <p>Регистрация закрывается за</p>
              <div class="form-group-container flex-row">
                <input type="text" placeholder="Введите число" v-model="item.registrationCloseBeforeInDays" />
                <p style="padding-left: 10px">дн.</p>
              </div>
            </div>
            <div class="invalid-msg" v-if="v$.item.registrationCloseBeforeInDays.$error">Введите корректное число</div>
          </div>
        </div>
      </div>

      <div class="card-info__row">
        <div class="card-info__row-col">
          <div class="form-group">
            <p class="form-label">Публикация на портале</p>
            <div class="form-group-checkboxes">
              <div class="checkboxInput">
                <input type="checkbox" class="form-check-input" v-model="item.isPublish" id="chbx_isPublish" />
                <label for="chbx_isPublish" class="form-check-label form-label">опубликовать</label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-info__row-col">
          <div class="form-group">
            <ComponentInput
              v-model="item.orderNumberDate"
              label="Номер и дата распоряжения"
              maxLength="500"
              placeholder="Введите номер и дату"
            />
          </div>
        </div>
      </div>
      <div class="card-info__row">
        <div class="card-info__row-col w-100">
          <p class="form-label">Ссылка на мероприятие</p>
          <a :href="publicUrl" target="_blank">{{ publicUrl }}</a>
        </div>
      </div>

      <div class="card-info__row btns">
        <ButtonStock title="Сохранить" v-on:click="save" />
        <ButtonStock btnType="white" title="Отмена" v-on:click="close" />
      </div>
    </div>
  </div>
</template>

<script>
  import { useVuelidate } from '@vuelidate/core';
  import { numeric } from '@vuelidate/validators';

  import ComponentInput from '@/common/components/ComponentInput';
  import DateTimeComponent from '@/common/components/dateTimeComponent';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import IconComponent from '@/common/ui/IconComponent.vue';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import API from '../api/event';
  import { ID_TYPE_BUSINESS_ADVICE, ID_TYPE_BUSINESS_ADVICE_RF } from '../utils/constants';

  //const selectValidator = (value) => value > 0;

  export default {
    name: 'EventCardAdd',
    components: {
      ComponentInput,
      ButtonStock,
      DateTimeComponent,
      IconComponent,
    },
    mixins: [security],
    data() {
      return {
        item: {
          mpkId: null,
          countryId: null,
          eventTypeId: null,
          isActive: true,
          eventDatesList: [{ date: null }],
          eventConductTypesIds: [],
          uid: null,
          mpkBusinessTipId: null,
        },
        select2Settings: Constants.select2Settings,
        select2SettingsSearchAll: Constants.select2SettingsSearchAll,

        mpkList: [],
        allType: [],
        countryList: [{ id: 0, text: Constants.emptySelectOptionText, selected: true }],
        eventConductTypeList: [],

        defaultCountryId: null,

        businessType: false,
        businessEventTypeList: [],
      };
    },
    created() {
      this.checkPermissionAndRedirect(Constants.Permissions.MpkEventsEdit);

      if (this.$route.query && this.$route.query.countryId > 0) {
        this.defaultCountryId = this.$route.query.countryId;
        this.item.countryId = this.$route.query.countryId;
      }

      this.loadCard();

      this.loadMpk();
      Utils.loadSelectOptions('countryList', this.countryList);
      Utils.loadSelectOptions('mpkEventTypeList', this.allType);
      Utils.loadSelectOptions('mpkEventConductTypeList', this.eventConductTypeList);
      API.getBusinesstipsearch()
        .then((response) => {
          if (response.data.length) {
            this.businessEventTypeList = response.data.map((i) => ({
              ...i,
              text: i.name,
            }));
          }
        })
        .catch((err) => {
          console.error('error', err);
        });
    },
    methods: {
      addEventDate() {
        this.item.eventDatesList.push({ date: null });
      },
      loadMpk() {
        API.getMpkForEvent({ countryId: this.defaultCountryId }).then((response) => {
          this.mpkList = response.data ? response.data : [];
        });
      },
      changeMpk() {
        if (this.item.mpkId > 0) {
          const item = this.mpkList.find((x) => x.id == this.item.mpkId);
          this.item.countryId = item ? item.countryId : '';
        }
      },
      changeBussines() {
        if (this.item.mpkBusinessTipId > 0) {
          const item = this.businessEventTypeList.find((x) => x.id == this.item.mpkBusinessTipId);
          this.item.countryId = item ? item.countryId : '';
        }
      },
      loadCard() {
        API.getUid().then((response) => {
          this.item.uid = response.data;
        });

        if (this.$route.query && this.$route.query.eventTypeId > 0)
          this.item.eventTypeId = this.$route.query.eventTypeId;
      },
      save() {
        this.v$.$touch();
        if (this.v$.$invalid) {
          Constants.alert.fire('Ошибка', 'Проверьте правильность заполнения полей', 'error');
          return;
        }

        var itemToSave = Object.assign({}, this.item);

        if (itemToSave.mpkId == 0) {
          itemToSave.mpkId = null;
        }
        itemToSave.eventDatesList = this.item.eventDatesList.filter((x) => x.date != null).map((x) => x.date);
        if (itemToSave.registrationCloseBeforeInDays == '') itemToSave.registrationCloseBeforeInDays = null;

        API.update(itemToSave)
          .then((response) => {
            Constants.alert.fire('Сохранение', Constants.commonSuccess, 'success');
            if (response && response.data.id) {
              this.$router.push({
                name: 'EventCard',
                params: { id: response.data.id, action: 'edit' },
              });
            }
          })
          .catch(() => {
            Constants.alert.fire('Сохранение', Constants.commonError, 'error');
          });
      },
      close() {
        this.$router.push({ name: 'Events' });
      },

      switchType(state) {
        this.item.eventTypeId = '';
        this.item.mpkBusinessTipId = '';
        this.item.mpkId = '';
        this.item.countryId = '';
        this.businessType = state;
      },
    },
    computed: {
      mpkFilterList() {
        var list = [{ id: 0, text: Constants.emptySelectOptionText, selected: true }];
        this.mpkList
          .map((x) => ({ id: x.id, text: x.name }))
          .forEach(function (el) {
            list.push(el);
          });

        return list;
      },
      publicUrl() {
        return Utils.getPublicBaseUrl() + 'events/' + this.item.uid;
      },

      getEventTypeListMPK() {
        return this.allType.filter((i) => +i.id !== ID_TYPE_BUSINESS_ADVICE && +i.id !== ID_TYPE_BUSINESS_ADVICE_RF);
      },

      getEventTypeListBA() {
        return this.allType.filter((i) => +i.id === ID_TYPE_BUSINESS_ADVICE || +i.id === ID_TYPE_BUSINESS_ADVICE_RF);
      },
    },
    setup: () => ({ v$: useVuelidate() }),
    validations() {
      return {
        item: {
          registrationCloseBeforeInDays: { numeric },
        },
      };
    },
  };
</script>

<style lang="scss">
  .date {
    &-container {
      margin-bottom: 16px;
      .v3dp__datepicker,
      .input-group-wrapper {
        margin-right: 8px;
      }
    }
  }
</style>

<style lang="scss" scoped>
  .form {
    &-label {
      font-weight: 400 !important;
      font-size: 14px;
      color: #231f20;
      line-height: 160%;
    }
    &-group-checkboxes {
      display: flex;
      flex-wrap: wrap;
      .checkboxInput {
        &:not(:last-of-type) {
          margin-right: 34px;
          margin-bottom: 34px;
        }
      }
      .form-check-input {
        left: 30px;
        top: 10px;
      }
    }
  }
  .date {
    &-container {
      display: flex;
      align-items: center;
    }
  }
  .card-info {
    &__row {
      justify-content: space-between;
      flex-wrap: wrap;
      &:nth-of-type(5) {
        .card-info__row-col {
          &:nth-of-type(2) {
            .form-group-container {
              display: flex;
              align-items: center;
              justify-content: space-between;
              p {
                font-weight: 400 !important;
                font-size: 14px;
                color: #231f20;
                line-height: 160%;
              }
              input {
                max-width: 220px;
                width: 100%;
              }
            }
          }
        }
      }
      &.btns {
        button {
          max-width: 221px;
          width: 100%;
        }
      }
    }

    &__switch {
      border-radius: 4px;
      background: $grey;
      display: inline-flex;
      padding: 4px;

      &-item {
        border-radius: 3px;
        background: transparent;
        transition: 0.3s ease;
        font: 500 15px / 15px 'Fira Sans';
        letter-spacing: -0.3px;
        color: $blue;
        padding: 8px 16px;
        cursor: pointer;

        &.active {
          background: #fff;
        }
      }
    }
  }
  @media (max-width: 768px) {
    .card-info__row.btns {
      button {
        max-width: unset;
        margin-bottom: 16px;
      }
    }
  }
</style>
